import {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_CRMaddon/partials'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'

export function PrivateRoutes() {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  // -------------------------------------------------------------------------------------------------

  // ================================= Masters ==========================
  const MembershipCategoryMasterPage = lazy(
    () => import('../modules/master-page/membership-category-page/MembershipCategoryMasterPage')
  )
  const DistrictMasterPage = lazy(
    () => import('../modules/master-page/district-master-page/DistrictMasterPage')
  )
  const CountryMasterPage = lazy(
    () => import('../modules/master-page/country-master-page/CountryMasterPage')
  )
  const StateMasterPage = lazy(
    () => import('../modules/master-page/state-master-page/StateMasterPage')
  )
  const TalukaMasterPage = lazy(
    () => import('../modules/master-page/taluka-master-page/TalukaMasterPage')
  )
  const NationalityMasterPage = lazy(
    () => import('../modules/master-page/nationality-master-page/NationalityMasterPage')
  )
  const EducationCategoryPage = lazy(
    () => import('../modules/master-page/education-category-master-page/EducationCategoryPage')
  )
  const EducationDepartmentPage = lazy(
    () => import('../modules/master-page/education-department-master-page/EducationDepartmentPage')
  )
  const DocumentTypePage = lazy(
    () => import('../modules/master-page/document-type-page/DocumentTypePage')
  )
  const DepartmentMasterPage = lazy(
    () => import('../modules/master-page/department-master-page/DepartmentMasterPage')
  )
  const DesignationMasterPage = lazy(
    () => import('../modules/master-page/designation-master-page/DesignationMasterPage')
  )

  const MemberTypeMasterPage = lazy(
    () => import('../modules/member-type-page/MemberTypeMasterPage')
  )
  const AssociationMemberMasterPage = lazy(
    () => import('../modules/member-page/association-member-pages/AssociatesMemberMasterPage')
  )
  const EditAssociationMemberMasterPage = lazy(
    () =>
      import(
        '../modules/member-page/association-member-pages/edit-master-pages/ViewAssociatesMasterPage'
      )
  )

  const AssociatesCorporateMasterPage = lazy(
    () => import('../modules/member-page/associates-corporate-pages/AssociatesCorporateMasterPage')
  )
  const AssociatesPlatinumMasterPage = lazy(
    () => import('../modules/member-page/associates-platinum-pages/AssociatesPlatinumMasterPage')
  )
  // ==============================================================
  const SmeMembersAssociation = lazy(
    () => import('../modules/reports-page/sme-members-association/SmeMembersAssociation')
  )
  const LargeMembersAssociation = lazy(
    () => import('../modules/reports-page/large-members-association/LargeMembersAssociation')
  )
  const PrivateSectorPartners = lazy(
    () => import('../modules/reports-page/private-sector-partners/PrivateSectorPartners')
  )
  // ===============================================================
  const SendEmailMasterPage = lazy(() => import('../modules/send-email-page/SendEmailMasterPage'))
  const ActivityMasterPage = lazy(() => import('../modules/activity-page/ActivityMasterPage'))
  // const MembersActivityMasterPage = lazy(
  //   () => import('../modules/activity-page/MembersActivityMasterPage')
  // )
  const AttendanceTrackingMasterPage = lazy(
    () => import('../modules/attendance-tracking-page/AttendanceTrackingMasterPage')
  )
  // ================================= Organization ==========================
  const EmployeeMasterPage = lazy(
    () => import('../modules/organization-page/employee-master-page/EmployeeMasterPage')
  )
  const UserMasterPage = lazy(
    () => import('../modules/organization-page/user-master-page/UserMasterPage')
  )
  // ================================= Registration ==========================
  const AssociatesMasterPage = lazy(
    () => import('../modules/registration-page/associates-page/AssociatesMasterPage')
  )
  const CorporatesMasterPage = lazy(
    () => import('../modules/registration-page/corporates-page/CorporatesMasterPage')
  )
  const AssociationSmallMasterPage = lazy(
    () => import('../modules/registration-page/association-small-page/AssociationSmallMasterPage')
  )
  const RegionalMasterPage = lazy(
    () => import('../modules/registration-page/regional-page/RegionalMasterPage')
  )
  const AssociationMediumMasterPage = lazy(
    () => import('../modules/registration-page/association-medium-page/AssociationMediumMasterPage')
  )
  const PrivateSectorPartnersMasterPage = lazy(
    () =>
      import(
        '../modules/registration-page/private-sector-partners-pages/PrivateSectorPartnersMasterPage'
      )
  )
  const PlatinumMasterPage = lazy(
    () => import('../modules/registration-page/platinum-page/PlatinumMasterPage')
  )
  const AssociatesGoldMasterPage = lazy(
    () => import('../modules/member-page/associates-gold-pages/AssociatesGoldMasterPage')
  )
  const GoldMasterPage = lazy(() => import('../modules/registration-page/gold-page/GoldMasterPage'))
  const AssociatesProjectMemberMasterPage = lazy(
    () =>
      import(
        '../modules/member-page/associates-project-member-pages/AssociatesProjectMemberMasterPage'
      )
  )
  const ProjectMemberMasterPage = lazy(
    () => import('../modules/registration-page/project-member-page/ProjectMemberMasterPage')
  )

  // ================================= View Member ==========================

  const AssociatesMemberMasterPage = lazy(
    () => import('../modules/member-page/association-member-pages/AssociatesMemberMasterPage')
  )
  const ViewAssociationMediumMemberMasterPage = lazy(
    () =>
      import(
        '../modules/member-page/view-member-association-medium-pages/ViewAssociationMediumMemberMasterPage'
      )
  )
  const ViewAssociationSmallMemberMasterPage = lazy(
    () =>
      import(
        '../modules/member-page/view-member-association-small-pages/ViewAssociationSmallMemberMasterPage'
      )
  )
  const ViewRegionalMemberMasterPage = lazy(
    () => import('../modules/member-page/view-member-regional-pages/ViewRegionalMemberMasterPage')
  )

  // ================================= View Non Member ==========================
  const BMONonMemberPrivateMasterPage = lazy(
    () =>
      import(
        '../modules/view-non-member-private-pages/view-bmo-pages/BMONonMemberPrivateMasterPage'
      )
  )
  const CorporatesNonMemberPrivateMasterPage = lazy(
    () =>
      import(
        '../modules/view-non-member-private-pages/view-corporates-pages/CorporatesNonMemberPrivateMasterPage'
      )
  )
  const MDANGONonMemberPrivateMasterPage = lazy(
    () =>
      import(
        '../modules/view-non-member-private-pages/view-mda-ngo-pages/MDANGONonMemberPrivateMasterPage'
      )
  )
  // -------------------------------------Activity Reports---------------------------
  const RegReportCompanyMasterPage = lazy(
    () => import('../modules/activity-reports/reg-report-company-master/RegReportCompanyMasterPage')
  )
  const RegReportEmployeeMasterPage = lazy(
    () =>
      import('../modules/activity-reports/reg-report-employee-master/RegReportEmployeeMasterPage')
  )
  const JoinReportMasterPage = lazy(
    () => import('../modules/activity-reports/join-report-master/JoinReportMasterPage')
  )

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/builder' component={BuilderPageWrapper} />
        <Route path='/crafted/pages/profile' component={ProfilePage} />
        <Route path='/crafted/pages/wizards' component={WizardsPage} />
        <Route path='/crafted/widgets' component={WidgetsPage} />
        <Route path='/crafted/account' component={AccountPage} />
        <Route path='/apps/chat' component={ChatPage} />
        <Route path='/menu-test' component={MenuTestPage} />

        <Route path='/memberType' component={MemberTypeMasterPage} />
        <Route path='/members/associationMember' component={AssociationMemberMasterPage} />
        <Route
          path='/members/associationMember/edit/:memberID'
          component={EditAssociationMemberMasterPage}
        />
        <Route path='/member/corporates' component={AssociatesCorporateMasterPage} />
        <Route path='/member/platinum' component={AssociatesPlatinumMasterPage} />
        <Route path='/member/gold' component={AssociatesGoldMasterPage} />
        <Route path='/member/project-member' component={AssociatesProjectMemberMasterPage} />
        {/* --------------------------- Masters ------------------------- */}
        <Route path='/master/membershipCategory' component={MembershipCategoryMasterPage} />
        <Route path='/master/district' component={DistrictMasterPage} />
        <Route path='/master/state' component={StateMasterPage} />
        <Route path='/master/country' component={CountryMasterPage} />
        <Route path='/master/taluka' component={TalukaMasterPage} />
        <Route path='/master/nationality' component={NationalityMasterPage} />
        <Route path='/master/eduDepartment' component={EducationDepartmentPage} />
        <Route path='/master/eduCategory' component={EducationCategoryPage} />
        <Route path='/master/documenttype' component={DocumentTypePage} />
        <Route path='/master/department' component={DepartmentMasterPage} />
        <Route path='/master/designation' component={DesignationMasterPage} />
        <Route path='/reports/smeMembersAssociation' component={SmeMembersAssociation} />
        <Route path='/reports/largeMembersAssociation' component={LargeMembersAssociation} />
        <Route path='/reports/privateSectorPartners' component={PrivateSectorPartners} />
        <Route path='/send-email' component={SendEmailMasterPage} />
        <Route path='/activity' component={ActivityMasterPage} />
        {/* <Route path='/activity/member/:activityid' component={MembersActivityMasterPage} /> */}
        <Route path='/attendanceTracking' component={AttendanceTrackingMasterPage} />
        {/* --------------------------- Organization ------------------------- */}
        <Route path='/organization/employee' component={EmployeeMasterPage} />
        <Route path='/organization/user' component={UserMasterPage} />
        {/* --------------------------- Registration --------------------------- */}
        <Route path='/registration/associates' component={AssociatesMasterPage} />
        <Route path='/registration/corporates' component={CorporatesMasterPage} />
        <Route path='/registration/associationSmall' component={AssociationSmallMasterPage} />
        <Route path='/registration/regional' component={RegionalMasterPage} />
        <Route path='/registration/associateMedium' component={AssociationMediumMasterPage} />
        <Route
          path='/registration/private-sector-partners'
          component={PrivateSectorPartnersMasterPage}
        />
        <Route path='/registration/platinum' component={PlatinumMasterPage} />
        <Route path='/registration/gold' component={GoldMasterPage} />
        <Route path='/registration/project-member' component={ProjectMemberMasterPage} />
        {/* --------------------------- View Member --------------------------- */}
        <Route path='/member/associates' component={AssociatesMemberMasterPage} />
        <Route path='/member/associateMedium' component={ViewAssociationMediumMemberMasterPage} />
        <Route path='/member/associationSmall' component={ViewAssociationSmallMemberMasterPage} />
        <Route path='/member/regional' component={ViewRegionalMemberMasterPage} />
        {/* --------------------------- View Non Member --------------------------- */}
        <Route path='/nonMember/BMO' component={BMONonMemberPrivateMasterPage} />
        <Route path='/nonMember/corporates' component={CorporatesNonMemberPrivateMasterPage} />
        <Route path='/nonMember/MDA/NGO' component={MDANGONonMemberPrivateMasterPage} />
        {/* ----------------------------------Activity-Report-------------------------- */}
        <Route path='/activity-report/reg-report-company' component={RegReportCompanyMasterPage} />
        <Route path='/activity-report/reg-report-employee' component={RegReportEmployeeMasterPage} />
        <Route path='/activity-report/join-report' component={JoinReportMasterPage} />
        <Redirect from='/' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
