import axios from 'axios'
import {UserModel} from '../models/UserModel'

const API_URL = process.env.REACT_APP_API_URL

export const LOGIN_URL = `${API_URL}/LogIn/EmployeeLogIn`

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/sa/user/login1`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel
// export function login(userName: string, password: string) {
//   return axios.post(LOGIN_URL, {
//     userName,
//     password,
//   })
// }
export function login(userName: string, password: string, typeID: number, subTypeID: number) {
  let api: string = ''
  if (typeID == 1) {
    api = '/LogIn/EmployeeLogIn'
  } else if (typeID == 2) {
    api = '/LogIn/MemberLogIn'
  }
  return axios.post(API_URL + api, {
    userName,
    password,
    typeID,
    subTypeID,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    firstName: firstname,
    lastName: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}
