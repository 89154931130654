import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {shallowEqual, useSelector} from 'react-redux'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {RootState} from '../../../../setup'

export function AsideMenuMain() {
  const intl = useIntl()
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      {/* <AsideMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Builder Layout'
        fontIcon='bi-layers'
      /> */}

      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Menu</span>
        </div>
      </div> */}

      {/* ********************************************************************************************************** */}

      {/* ------------- Member ------------- */}
      <AsideMenuItemWithSub
        to='/member'
        title='Member'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/communication/com006.svg'
        dislpay={user.typeID === 1 || (user.typeID === 2 && user.roleID === 1) ? '' : 'd-none'}
      >
        <AsideMenuItem to='/member/associationSmall' title='Association Small' hasBullet={true} />
        <AsideMenuItem to={`/member/associateMedium`} title='Association Medium' hasBullet={true} />
        <AsideMenuItem to='/member/corporates' title='Corporates' hasBullet={true} />
        <AsideMenuItem to='/member/associates' title='Associates' hasBullet={true} />
        <AsideMenuItem to='/member/regional' title='Regional' hasBullet={true} />
        <AsideMenuItem to='/member/platinum' title='Platinum' hasBullet={true} />
        <AsideMenuItem to='/member/project-member' title='Project Member' hasBullet={true} />
        <AsideMenuItem to='/member/gold' title='Gold' hasBullet={true} />
      </AsideMenuItemWithSub>

      {/* ------------- Non-Member Private Sector & Partner ------------- */}
      <AsideMenuItemWithSub
        to='/nonMember'
        title='Non-Member Private Sector & Partner'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen029.svg'
        dislpay={user.typeID === 1 || (user.typeID === 2 && user.roleID === 3) ? '' : 'd-none'}
      >
        <AsideMenuItem to='/nonMember/BMO' title='BMO' hasBullet={true} />
        <AsideMenuItem to={`/nonMember/corporates`} title='Corporates' hasBullet={true} />
        <AsideMenuItem to='/nonMember/MDA/NGO' title='MDA/NGO' hasBullet={true} />
      </AsideMenuItemWithSub>

      {/* ------------- Registration ------------- */}
      <AsideMenuItemWithSub
        to='/registration'
        title='Registration'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/communication/com006.svg'
      >
        <AsideMenuItem
          to='/registration/associationSmall'
          title='Association Small'
          hasBullet={true}
          display={
            (user.typeID === 1 && (user.roleID === 1 || user.roleID === 2)) ||
            (user.typeID === 2 && user.roleID === 1)
              ? ''
              : 'd-none'
          }
        />
        <AsideMenuItem
          to={`/registration/associateMedium`}
          title='Association Medium'
          hasBullet={true}
          display={
            (user.typeID === 1 && (user.roleID === 1 || user.roleID === 2)) ||
            (user.typeID === 2 && user.roleID === 1)
              ? ''
              : 'd-none'
          }
        />
        <AsideMenuItem
          to='/registration/corporates'
          title='Corporates'
          hasBullet={true}
          display={
            (user.typeID === 1 && (user.roleID === 1 || user.roleID === 2)) ||
            (user.typeID === 2 && user.roleID === 1)
              ? ''
              : 'd-none'
          }
        />
        <AsideMenuItem
          to='/registration/associates'
          title='Associates'
          hasBullet={true}
          display={
            (user.typeID === 1 && (user.roleID === 1 || user.roleID === 2)) ||
            (user.typeID === 2 && user.roleID === 1)
              ? ''
              : 'd-none'
          }
        />
        <AsideMenuItem
          to='/registration/regional'
          title='Regional'
          hasBullet={true}
          display={
            (user.typeID === 1 && (user.roleID === 1 || user.roleID === 2)) ||
            (user.typeID === 2 && user.roleID === 1)
              ? ''
              : 'd-none'
          }
        />
        <AsideMenuItem
          to='/registration/private-sector-partners'
          title='Non Members Private Sector & Partner'
          hasBullet={true}
          display={
            (user.typeID === 1 && (user.roleID === 1 || user.roleID === 2)) ||
            (user.typeID === 2 && user.roleID === 3)
              ? ''
              : 'd-none'
          }
        />
        <AsideMenuItem
          to='/registration/platinum'
          title='Platinum'
          hasBullet={true}
          display={
            (user.typeID === 1 && (user.roleID === 1 || user.roleID === 2)) ||
            (user.typeID === 2 && user.roleID === 3)
              ? ''
              : 'd-none'
          }
        />
        <AsideMenuItem
          to='/registration/gold'
          title='Gold'
          hasBullet={true}
          display={
            (user.typeID === 1 && (user.roleID === 1 || user.roleID === 2)) ||
            (user.typeID === 2 && user.roleID === 3)
              ? ''
              : 'd-none'
          }
        />
        <AsideMenuItem
          to='/registration/project-member'
          title='Project Member'
          hasBullet={true}
          display={
            (user.typeID === 1 && (user.roleID === 1 || user.roleID === 2)) ||
            (user.typeID === 2 && user.roleID === 3)
              ? ''
              : 'd-none'
          }
        />
      </AsideMenuItemWithSub>

      {/* ------------- Organization ------------- */}
      <AsideMenuItemWithSub
        to='/organization'
        title='Organization'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen025.svg'
        dislpay={(user.typeID === 1 && user.roleID === 1) || user.roleID === 2 ? '' : 'd-none'}
      >
        <AsideMenuItem to='/organization/employee' title='Employee' hasBullet={true} />
        <AsideMenuItem to='/organization/user' title='User' hasBullet={true} />
      </AsideMenuItemWithSub>

      {/* ------------- Master ------------- */}
      <AsideMenuItemWithSub
        to='/master'
        title='Master'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen025.svg'
        dislpay={(user.typeID === 1 && user.roleID === 1) || user.roleID === 2 ? '' : 'd-none'}
      >
        <AsideMenuItem to='/master/country' title='Country' hasBullet={true} />
        <AsideMenuItem to='/master/department' title='Department' hasBullet={true} />
        <AsideMenuItem to='/master/designation' title='Designation' hasBullet={true} />
        <AsideMenuItem to='/master/district' title='District' hasBullet={true} />
        <AsideMenuItem to='/master/documenttype' title='Document Type' hasBullet={true} />
        <AsideMenuItem to='/master/eduCategory' title='Education Category' hasBullet={true} />
        <AsideMenuItem to='/master/eduDepartment' title='Education Department' hasBullet={true} />
        <AsideMenuItem to='/master/nationality' title='Nationality' hasBullet={true} />
        <AsideMenuItem to='/master/state' title='County' hasBullet={true} />
        {/* <AsideMenuItem to='/master/taluka' title='Taluka' hasBullet={true} />
        <AsideMenuItem
          to='/master/membershipCategory'
          title='Membership Category'
          hasBullet={true}
        /> */}
      </AsideMenuItemWithSub>

      {/* ------------- Report ------------- */}
      <AsideMenuItemWithSub
        to='/reports'
        title='Reports'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen025.svg'
        dislpay={user.typeID === 2 ? 'd-none' : ''}
      >
        <AsideMenuItem
          to='/reports/smeMembersAssociation'
          title='SME Members Association'
          hasBullet={true}
        />
        <AsideMenuItem
          to='/reports/largeMembersAssociation'
          title='Large Members Association'
          hasBullet={true}
        />

        <AsideMenuItem
          to='/reports/privateSectorPartners'
          title='Private Sector Partners'
          hasBullet={true}
        />
      </AsideMenuItemWithSub>
      {/* ------------- Activity Report ------------- */}
      <AsideMenuItemWithSub
        to='/activity-report'
        title='Activity Reports'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen019.svg'
        dislpay={user.typeID === 2 ? 'd-none' : ''}
      >
        <AsideMenuItem
          to='/activity-report/reg-report-company'
          title='Registration Report Company'
          hasBullet={true}
        />
        <AsideMenuItem
          to='/activity-report/reg-report-employee'
          title='Registration Report Employee'
          hasBullet={true}
        />

        <AsideMenuItem
          to='/activity-report/join-report'
          title='Join Report'
          hasBullet={true}
        />
      </AsideMenuItemWithSub>

      {/* ------------- Send Email ------------- */}
      <AsideMenuItem
        to='/send-email'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Send Email'
        fontIcon='bi-layers'
        display={(user.typeID === 1 && user.roleID === 1) || user.roleID === 2 ? '' : 'd-none'}
      />

      {/* ------------- Activity ------------- */}
      <AsideMenuItem
        to='/activity'
        icon='/media/icons/duotune/general/gen024.svg'
        title='Activity'
        fontIcon='bi-layers'
        display={(user.typeID === 1 && user.roleID === 1) || user.roleID === 2 ? '' : 'd-none'}
      />

      {/* ------------- Attendance Tracking ------------- */}
      {/* <AsideMenuItem
        to='/attendanceTracking'
        icon='/media/icons/duotune/general/gen028.svg'
        title='Attendance Tracking'
        fontIcon='bi-layers'
        display={(user.typeID === 1 && user.roleID === 1) || user.roleID === 2 ? '' : 'd-none'}
      /> */}

      {/* ********************************************************************************************************** */}
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div>

      <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <AsideMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
          <AsideMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/error'
        title='Errors'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}
